//React
import React from 'react'

//Icons
import FeatureIcon from './featureIcon'

const featureData = [
  {
    rowTitle: 'Voice creation',
    feature1: 'None',
    feature2: 'Create content on-the-go using AI-powered voice commands',

    icon: 'microphone',
    color: 'dkorange',
    bglevel: 6,
  },
  {
    rowTitle: 'Solution focus',
    feature1:
      'Design tool, focused on creativity & detailed graphic design-type options',
    feature2:
      'Rapid customization platform, focused on relevant content, enablement & business results',
    icon: 'bullseye',
    color: 'dkgreen',
    bglevel: 6,
  },
  {
    rowTitle: 'Content templates',
    feature1:
      'Horizontal breadth of templates: everything from business to baby shower',
    feature2:
      'Templates for your industry: deeply relevant content that moves your business forward',
    icon: 'imagespread',
    color: 'sky',
    bglevel: 6,
  },
  {
    rowTitle: 'Strategy',
    feature1:
      'Need to develop marketing strategy, and translate that from scratch into creative execution and template components',
    feature2:
      'Turnkey industry-based marketing strategy with prebuilt templates aligned to your business - no need to reinvent',
    icon: 'lightning',
    color: 'dkblue',
    bglevel: 6,
  },
  {
    rowTitle: 'Enablement',
    feature1: 'None',
    feature2:
      'Equip team members with the latest templates, promotions & campaigns - and alert them via email or SMS',
    icon: 'users',
    color: 'purple',
    bglevel: 4,
  },
  {
    rowTitle: 'Mobile',
    feature1:
      'Focused on desktop tool, with mobile app as an afterthought - and then need to get team to download & install the app',
    feature2:
      'Mobile-first solution for on-the-go teams: no app necessary to download or install',
    icon: 'iphone',
    color: 'pink',
    bglevel: 5,
  },
]
const Snippet = (props) => (
  <div class="container py-4">
    <div class="row d-none d-md-flex flex-row justify-content-center align-items-start gradient-callout-slate round-corners-top">
      <div class="col-md-2"></div>
      <div class="col-md-4 py-3 h4 white-1">Storylava visual selling</div>
      <div class="col-md-4 py-3 h4 gray-6 font-weight-light">
        Templated design tools
      </div>
    </div>
    {/* <hr class="d-none d-md-block" size={1} /> */}

    {featureData.map((row, i) => (
      <div
        class={`feature-comparison row d-flex flex-row justify-content-center align-items-start my-2 pt-4 pb-2 rounded-corners bg-${row.color}-${row.bglevel} `}
      >
        <div class="feature-comparison-title d-flex flex-column align-items-center justify-content-center align-items-md-end justify-content-md-center col-md-2 text-left mb-2 mb-md-0 text-md-right pr-4 font-weight-light italic">
          <p class={`${row.color}-1`}>{row.rowTitle}</p>
          {row.icon ? (
            <FeatureIcon context={row.icon} color={row.color} />
          ) : null}
        </div>

        <div class="col-md-4 line-height-3">
          <div class="d-block d-md-none font-weight-medium mb-1">
            Storylava visual selling
          </div>
          <p class={`black-1 font-weight-light`}>{row.feature2}</p>
        </div>
        <div class="col-md-4 line-height-3 black-5">
          <div class="d-block d-md-none black-5 font-weight-medium mb-1">
            Templated design tools
          </div>
          <p class={`black-5 font-weight-light`}>{row.feature1}</p>
        </div>
      </div>
    ))}
  </div>
)

export default Snippet
